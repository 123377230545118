export type DB_TYPES =
  | 'database'
  | 'user'
  | 'database-org'
  | 'customer'
  | 'ewz';

export type TECHNOLOGY_TYPE =
  | 'conversion-tech'
  | 'storage-tech'
  | 'network-tech'
  | 'tech-package'
  | 'energy-demand'
  | 'solar-resource'
  | 'imports'
  | 'exports';

export type DISPLAY_TYPE = 'SAVED_PROFILE' | 'PROFILE' | 'TECHNOLOGY';

export interface DB_CONFIG {
  label: string;
  value: TECHNOLOGY_TYPE;
  presigned?: boolean;
  canUpload?: boolean;
  canDeleteItem?: boolean;
  valueLabel?: string;
  valueKey?: string;
  type: DISPLAY_TYPE;
  onlyUpload?: boolean;
}

export interface TECH extends DB_CONFIG {
  canDeleteCategory?: boolean;
  categoryLabel?: string;
  categoryKey?: string;
  type: 'TECHNOLOGY';
}

export interface PROFILE extends DB_CONFIG {
  canDeleteCategory?: boolean;
  categoryLabel?: string;
  categoryKey?: string;
  canDeleteType?: boolean;
  typeLabel?: string;
  typeKey?: string;
  type: 'PROFILE';
}

export interface SAVED_PROFILE extends DB_CONFIG {
  type: 'SAVED_PROFILE';
}

export type ITEM_CONFIG = TECH | PROFILE | SAVED_PROFILE;

interface DB {
  label: string;
  value: string;
  options: ITEM_CONFIG[];
}

export const TECHNOLOGY_PACKAGES: TECH = {
  label: 'Technology Packages',
  value: 'tech-package',
  valueLabel: 'name',
  valueKey: 'guid',
  categoryLabel: 'name',
  categoryKey: 'guid',
  canDeleteCategory: true,
  type: 'TECHNOLOGY',
};

export const technologies: TECH[] = [
  {
    label: 'Conversion Technologies',
    value: 'conversion-tech',
    valueLabel: 'technologyName',
    valueKey: 'conversionTechGuid',
    canDeleteItem: true,
    canDeleteCategory: true,
    type: 'TECHNOLOGY',
  },
  {
    label: 'Storage Technologies',
    value: 'storage-tech',
    valueLabel: 'name',
    valueKey: 'storageTechGuid',
    canDeleteItem: true,
    canDeleteCategory: true,
    type: 'TECHNOLOGY',
  },
  TECHNOLOGY_PACKAGES,
  {
    label: 'Network Technologies',
    value: 'network-tech',
    valueLabel: 'name',
    valueKey: 'databaseTechGuid',
    canDeleteItem: true,
    canDeleteCategory: true,
    type: 'TECHNOLOGY',
  },
];

export const onSiteResources: PROFILE[] = [
  {
    label: 'Solar Resources Profiles',
    value: 'solar-resource',
    presigned: true,
    type: 'PROFILE',
    onlyUpload: true,
    valueKey: 'name',
    valueLabel: 'slopeAndOrientation',
  },
];

export const onSiteResourceProfiles: SAVED_PROFILE[] = [
  {
    label: 'Solar Resources Profiles',
    value: 'solar-resource',
    valueLabel: 'name',
    valueKey: 'guid',
    presigned: true,
    canDeleteItem: true,
    type: 'SAVED_PROFILE',
  },
];
export const ENERGY_DEMAND_PROFILE: PROFILE = {
  label: 'Energy Demands Profiles',
  value: 'energy-demand',
  categoryLabel: 'energyDemandTypeDisplayValue',
  categoryKey: 'energyDemandType',
  typeKey: 'buildingType',
  typeLabel: 'buildingTypeDisplayValue',
  valueLabel: 'displayValue',
  valueKey: 'energyDemandMetadataGuid',
  presigned: true,
  type: 'PROFILE',
  onlyUpload: true,
  canDeleteCategory: true,
  canDeleteItem: true,
};

export const energyDemands = [ENERGY_DEMAND_PROFILE];

export const energyDemandsProfiles: SAVED_PROFILE[] = [
  {
    label: 'Energy Demands Profiles',
    value: 'energy-demand',
    valueLabel: 'name',
    valueKey: 'guid',
    presigned: true,
    canDeleteItem: true,
    type: 'SAVED_PROFILE',
  },
];

export const impex: TECH[] = [
  {
    label: 'Imports',
    value: 'imports',
    valueLabel: 'year',
    valueKey: 'guid',
    canUpload: false,
    canDeleteItem: true,
    type: 'TECHNOLOGY',
  },
  {
    label: 'Exports',
    value: 'exports',
    valueLabel: 'year',
    valueKey: 'guid',
    canUpload: false,
    canDeleteItem: true,
    type: 'TECHNOLOGY',
  },
];

const mapToUserRights = (items: ITEM_CONFIG[], admin: boolean) => {
  return items.map((i) => ({
    ...i,
    canUpload: admin && i.canUpload,
    canDeleteCategory: admin && (i as any).canDeleteCategory,
    canDeleteType: admin && (i as any).canDeleteType,
    canDeleteItem: admin && i.canDeleteItem,
    onlyUpload: i.onlyUpload,
  }));
};

export const databases = (admin: boolean): DB[] => [
  {
    label: 'Energy Demands',
    value: 'energy-demands',
    options: mapToUserRights(energyDemands, admin),
  },
  {
    label: 'On-site resources',
    value: 'on-site-resources',
    options: mapToUserRights(onSiteResources, admin),
  },
  {
    label: 'Technologies',
    value: 'technologies',
    options: mapToUserRights(technologies, admin),
  },
];

export const databasesUser: DB[] = [
  {
    label: 'Energy Demands',
    value: 'energy-demands',
    options: energyDemandsProfiles,
  },
  {
    label: 'On-site resources',
    value: 'on-site-resources',
    options: onSiteResourceProfiles,
  },
  {
    label: 'Technologies',
    value: 'technologies',
    options: technologies,
  },
];

export const getDatabases = (
  symphenyAdmin: boolean,
  admin: boolean,
): Record<DB_TYPES, DB[]> => {
  return {
    database: databases(symphenyAdmin),
    user: databasesUser,
    'database-org': databases(admin),
    customer: databases(symphenyAdmin),
    ewz: [],
  };
};
