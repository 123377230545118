import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy, signal } from '@angular/core';
import {
  mapDataZ,
  ResponseModel,
  SymphenyDate,
} from '@sympheny/utils/data-access';
import { EnvironmentService } from '@sympheny/utils/environment';
import { isEqual, xorWith } from 'lodash-es';
import { catchError, concatMap, map, of, Subscription, timer } from 'rxjs';
import { z } from 'zod';

const DatabaseLogSchema = z.object({
  startTime: SymphenyDate().nullish(),
  endTime: SymphenyDate().nullish(),
  result: z.string().nullish(),
  type: z.string().nullish(),
  excelFile: z.string().nullish(),
  userEmail: z.string().nullish(),
  profileType: z.string().nullish(),
});
export type DatabaseLog = z.infer<typeof DatabaseLogSchema>;

const TIMEOUT = 2000;

@Injectable()
export class DatabaseLogCollection implements OnDestroy {
  public logs = signal<DatabaseLog[]>([]);
  protected readonly idKey = 'startTime';
  public initialLoad = true;
  private readonly base = this.environmentService.getValue('base');
  private readonly successFn: Set<(items: DatabaseLog) => void> = new Set();

  private subscription: Subscription;

  constructor(
    private readonly http: HttpClient,
    private readonly environmentService: EnvironmentService,
  ) {
    this.load();
  }

  protected load() {
    this.subscription = timer(0, TIMEOUT)
      .pipe(
        concatMap(() =>
          this.http
            .get<ResponseModel<DatabaseLog[]>>(`${this.base}db-update`)
            .pipe(
              mapDataZ(DatabaseLogSchema),
              map((items) => this.checkForNewSuccess(items)),
              catchError((error) => {
                console.error(error);
                return of([]);
              }),
            ),
        ),
      )
      .subscribe();
  }

  public ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  public checkForNewSuccess(items: DatabaseLog[]) {
    const differences = xorWith(this.logs(), items, isEqual);

    if (differences.length === 0) {
      return items;
    }

    if (!this.initialLoad) {
      const success = differences.filter(
        (d) => d.result?.toLowerCase() === 'success',
      );

      if (success.length) {
        this.successFn.forEach((fn) => fn(success));
      }
    }

    this.logs.set(items);
    this.initialLoad = false;

    return items;
  }

  public registerSuccess(successFn: (items: DatabaseLog) => void) {
    this.successFn.add(successFn);
  }
}
