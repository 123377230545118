import { maxBy, minBy } from 'lodash-es';

import {
  ArrowPosition,
  Graph,
  GraphData,
  Link,
  Node,
  OrthogonalPathData,
  OrthogonalPathDataObj,
} from '../../../model';

const createPathDataObject = (
  original: OrthogonalPathDataObj | undefined,
  newData: Partial<OrthogonalPathDataObj>,
  {
    negativeOffset = false,
    breakOffset,
    distanceBetweenNodes,
  }: {
    negativeOffset?: boolean;
    breakOffset?: 'end';
    distanceBetweenNodes?: number;
  },
): OrthogonalPathDataObj => {
  const pathData = {
    ...(original ?? {
      source: { x: 0, y: 0, height: 0 },
      target: { x: 0, y: 0, height: 0 },
      arrowRotate: false,
      arrowPosition: 'none',
      breakOffset: 0,
    }),
    ...newData,
  } as OrthogonalPathDataObj;

  const s1 = Math.min(pathData.source.x, pathData.target.x);
  const s2 = Math.max(pathData.source.x, pathData.target.x);
  // TODO should be based on the distance between the nodes, this is equal divided

  switch (breakOffset) {
    case 'end':
      pathData.breakOffset = s2 - s1;
      if (negativeOffset) {
        pathData.breakOffset = -pathData.breakOffset;
      }
      break;

    default:
      {
        pathData.breakOffset = s2 - s1 - distanceBetweenNodes;

        if (pathData.breakOffset < distanceBetweenNodes) {
          pathData.breakOffset = (s2 - s1) / 2;
        }

        if (negativeOffset) {
          pathData.breakOffset = -pathData.breakOffset;
        }
      }
      break;
  }

  return pathData;
};

export const computeNormalBreak = (
  { x: sx }: OrthogonalPathData,
  { x: tx }: OrthogonalPathData,
) => {
  return sx + (tx - sx) / 2;
};

const getTargetLinks = (data: GraphData, node: Node) => {
  return data
    .getTargetLinks(node)
    .filter((t) => !!t.orthogonalPathData)
    .sort(sortOrthogonalLinks('source'));
};

const getSourceLinks = (data: GraphData, node: Node) => {
  return data
    .getSourceLinks(node)
    .filter((t) => !!t.orthogonalPathData)
    .sort(sortOrthogonalLinks('target'));
};

const computeLinksY = (node: Node, links: Link[]) => {
  const height = node.nodeHeight;
  const totalLinks = links.filter((l) => !(l.circular && l.isSelfLinking));
  const offsetTarget = height / (totalLinks.length + 1);
  let nextOffset = 0;
  const y = node.y0;

  links.forEach((link) => {
    nextOffset = nextOffset + offsetTarget;
    const field = link.source === node._id ? 'source' : 'target';
    const original = link.orthogonalPathData[field];

    link.setValue('orthogonalPathData', {
      ...link.orthogonalPathData,

      [field]: { ...original, y: y + nextOffset },
    });
  });
};
const computeLinksX = (node: Node, links: Link[]) => {
  const height = node.nodeHeight;
  const totalLinks = links.filter((l) => !(l.circular && l.isSelfLinking));
  const offsetTarget = height / (totalLinks.length + 1);
  let nextOffset = 0;
  const x = node.x0;

  links.forEach((link) => {
    nextOffset = nextOffset + offsetTarget;
    const field = link.source === node._id ? 'source' : 'target';
    const original = link.orthogonalPathData[field];

    link.setValue('orthogonalPathData', {
      ...link.orthogonalPathData,

      [field]: { ...original, x: x + nextOffset },
    });
  });
};

const computeAggregatedLinks = (
  node: Node,
  sourceLinks: Link[],
  targetLinks: Link[],
  // offset to ensure that we are always drawing the lines between the nodes
  offset: number,
) => {
  let baseLink: Link;
  let baseX = node.nodePositionCenterX;

  let linksAlignToField: 'source' | 'target';
  let otherLinksAlignToField: 'source' | 'target';
  let links: Link[];

  if (sourceLinks.length === 1) {
    baseLink = sourceLinks[0];
    baseX -= offset;
    linksAlignToField = 'source';
    otherLinksAlignToField = 'target';
    links = targetLinks;
  } else {
    baseX += offset;
    baseLink = targetLinks[0];

    linksAlignToField = 'target';
    otherLinksAlignToField = 'source';
    links = sourceLinks;
  }

  // compute the vertical line
  const all = [links, baseLink].flat();

  let minY = minBy(all, (l) => l.orthogonalPathData[linksAlignToField].y)
    .orthogonalPathData[linksAlignToField].y;
  let maxY = maxBy(all, (l) => l.orthogonalPathData[linksAlignToField].y)
    .orthogonalPathData[otherLinksAlignToField].y;

  const toAlign = baseLink.orthogonalPathData[linksAlignToField];
  if (minY > toAlign.y) {
    minY = toAlign.y;
  }
  if (maxY < toAlign.y) {
    maxY = toAlign.y;
  }
  links.forEach((link) => {
    const al = link.orthogonalPathData[linksAlignToField];
    link.setValue('orthogonalPathData', {
      ...link.orthogonalPathData,
      [otherLinksAlignToField]: { ...al, x: baseX },
    });
  });

  baseLink.setValue('orthogonalPathData', {
    ...baseLink.orthogonalPathData,
    [linksAlignToField]: {
      x: baseX,
      y: toAlign.y,
    },
    verticalLink: {
      source: { x: baseX, y: minY },
      target: { x: baseX, y: maxY },
    },
  });
};

const getArrowPosition = (
  link: Link,
  source: Node,
  target: Node,
): ArrowPosition => {
  if (link.isSelfLinking) {
    return 'both';
  }
  if (target.aggregate) {
    return 'none';
  }

  return 'end';
};
const getSource = (
  link: Link,
  source: Node,
  target: Node,
): OrthogonalPathData => {
  let x = source.x1;
  let y = source.nodePositionCenterY;
  if (source.aggregate) {
    y = target.nodePositionCenterY;
    x = source.nodePositionCenterX;
  } else if (x > target.nodePositionCenterX) {
    x = source.x0;
  }

  return { x, y, height: source.nodeHeight };
};

const getTarget = (
  link: Link,
  source: Node,
  target: Node,
): OrthogonalPathData => {
  let x = target.x0;
  let y = target.nodePositionCenterY;

  if (source.sameSourceTarget) {
    x = target.nodePositionCenterX;
    y = y < source.nodePositionCenterY ? target.y1 : target.y0;
  } else if (target.aggregate) {
    y = source.nodePositionCenterY;
    x = target.nodePositionCenterX;
  } else if (x < source.nodePositionCenterX) {
    // for source aggregate this is always true on small screen
    // console.log(target.name, x, source.name, source.nodePositionCenterX);
    if (!source.aggregate) x = target.x1;
  }

  return { x, y, height: source.nodeHeight, vertical: source.sameSourceTarget };
};

const computeLinkPaths = (
  link: Link,
  { graph: data, settings }: Readonly<Graph<any, any>>,
) => {
  if (link.circular && link.isSelfLinking) return;

  const { source, target } = data.getNodeLinks(link);

  const sourcePath = getSource(link, source, target);
  const targetPath = getTarget(link, source, target);
  const rotatePath = !source.aggregate && target.x0 < source.x1;

  link.setValue(
    'orthogonalPathData',
    createPathDataObject(
      link.orthogonalPathData,
      {
        source: sourcePath,
        target: targetPath,
        arrowPosition: getArrowPosition(link, source, target),
        arrowRotate: rotatePath,
      },
      {
        distanceBetweenNodes: settings.minNodePadding.x,
        negativeOffset: rotatePath,
        breakOffset: source.sameSourceTarget ? 'end' : undefined,
      },
    ),
  );
};

const computeNormalLink = (
  node: Node,
  sourceLinks: Link[],
  targetLinks: Link[],
) => {
  const links = { left: [], right: [], top: [], bottom: [] };

  const decideSide = (l: Link[], test: 'target' | 'source') => {
    l.forEach((t) => {
      if (!t.orthogonalPathData) return;
      const target = t.orthogonalPathData[test];
      if (target.x === node.x0) {
        links.left.push(t);
      } else if (target.x === node.x1) {
        links.right.push(t);
      } else if (target.y < node.y0) {
        links.top.push(t);
      } else {
        links.bottom.push(t);
      }
    });
  };

  decideSide(targetLinks, 'target');
  decideSide(sourceLinks, 'source');

  computeLinksY(node, links.left);
  computeLinksY(node, links.right);
  computeLinksX(node, links.top);
  computeLinksX(node, links.bottom);
  return;
};
const computeLinkNodeY = (
  node: Node,
  { graph: data, settings }: Readonly<Graph<any, any>>,
) => {
  const minDistance = settings.minNodePadding.y / 2;

  const targetLinks = getTargetLinks(data, node);
  const sourceLinks = getSourceLinks(data, node);

  if (node.aggregate) {
    computeAggregatedLinks(node, sourceLinks, targetLinks, minDistance);
    return;
  }
  computeNormalLink(node, sourceLinks, targetLinks);
};

const sortOrthogonalLinks =
  (key: 'source' | 'target') => (l1: Link, l2: Link) => {
    const o1 = l1.orthogonalPathData![key];
    const o2 = l2.orthogonalPathData![key];
    const offset = 10;

    if (o1.y - o2.y < offset) return -1;

    if (o1.y === o2.y) console.warn('oops');

    return 1;
  };

export const computePaths = (graph: Graph<any, any>) => {
  const { graph: data } = graph;

  // Calculate a first the x values of the links
  data.forEachLink((link) => computeLinkPaths(link, graph));

  // Calculate a first the y values of the links based on node input/outputs
  data.forEachNode((node) => {
    computeLinkNodeY(node, graph);
  });

  data.forEachNode((node) => {
    if (!node.aggregate) return;

    computeLinkNodeY(node, graph);
  });

  return;
};
