<ng-template [cdkPortalOutlet]></ng-template>

<ng-container *ngIf="!action; else showAction" [ngSwitch]="type">
  <ng-container *ngSwitchCase="'component'"></ng-container>
  <ng-container *ngSwitchCase="'template'"></ng-container>
  <ng-container *ngSwitchCase="'integer'">
    <span class="sympheny--table_align-number"> {{ value }} </span>
  </ng-container>
  <ng-container *ngSwitchCase="'color'">
    <div
      class="rounded-full border w-4 h-4"
      [style.background-color]="value"
    ></div>
  </ng-container>
  <ng-container *ngSwitchCase="'number'">
    <span class="sympheny--table_align-number"> {{ value }} </span>
  </ng-container>
  <ng-container *ngSwitchCase="'datetime'">
    <span *ngIf="value">
      {{ value | date: 'medium' }}
    </span>
  </ng-container>
  <ng-container *ngSwitchCase="'translate'">
    {{ translatePrefix + value | transloco }}
  </ng-container>
  <ng-container *ngSwitchCase="'array'">
    <p *ngFor="let v of value; let i = index">
      <ng-container *ngIf="i < 5">
        {{ v }}
      </ng-container>
      <span *ngIf="i === 5" [matTooltip]="tooltip" class="pre-line">...</span>
    </p>
  </ng-container>
  <ng-container *ngSwitchDefault> {{ value }}</ng-container>
</ng-container>

<ng-template #showAction>
  <sympheny-table-action
    [action]="action"
    [element]="element"
    [index]="rowIndex"
  />
</ng-template>
