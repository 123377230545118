import { ElementRef } from '@angular/core';

import { ArrowPosition, CircularLinkType, Link } from './link';
import { Node } from './node';

export type GraphArrow = {
  draw: boolean;
  length: number;
  gapLength: number;
  headSize: number;
  path: (link: Link) => string;
  color: string;
};

export type LinkId = Readonly<Pick<Link, '_id'>>;
export type NodeId = Readonly<Pick<Node, '_id'>>;
export type Graph<
  NODE_TYPE extends Node = Node,
  LINK_TYPE extends Link = Link,
> = {
  graph: GraphData<NODE_TYPE, LINK_TYPE>;
  nodeColor: (d: NODE_TYPE) => string | null;
  nodeTooltip: (d: NODE_TYPE) => string | null;
  linkArrow?: (
    d: LINK_TYPE,
    source: NODE_TYPE,
    target: NODE_TYPE,
  ) => ArrowPosition | null;
  linkColor: (
    d: LINK_TYPE,
    nodes: { source: NODE_TYPE; target: NODE_TYPE },
  ) => string;
  linkTooltip: (
    d: LINK_TYPE,
    source: NODE_TYPE,
    target: NODE_TYPE,
  ) => string | null;
  nodeText: (d: NODE_TYPE) => string | null;
  getNodeID: (d: any) => string;
  settings: GraphSettings<NODE_TYPE>;
  arrow: GraphArrow | null;
  useVirtualRoutes: boolean;
  tooltip: ElementRef;
};
export type GraphExtend = {
  x0: number;
  y0: number;
  x1: number;
  y1: number;
  py: number;
  ky: number;
};

// TODO make everything readonly so cache can be used
export type GraphData<
  NODE_TYPE extends Node = Node,
  LINK_TYPE extends Link = Link,
> = {
  // Calculate the columns data
  computeColumns(): void;
  // links: LINK_TYPE[];
  // nodes: NODE_TYPE[];

  replacedLinks: any[];
  extend: Readonly<GraphExtend>;

  setExtendValue(key: keyof GraphExtend, value: number): void;
  // Get the links that use the node as a source
  getNodeLinks(link: Link): Readonly<{ source: NODE_TYPE; target: NODE_TYPE }>;
  getNodeSource(link: Link): NODE_TYPE;
  getNodeTarget(link: Link): NODE_TYPE;
  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  getTargetLinks(node: NODE_TYPE | Node): Link[];
  getSourceLinks(node: NODE_TYPE | Node): Link[];

  totalLinks(key: 'sourceLinkMap' | 'targetLinkMap', node: Node): number;

  // #region Node functions
  forEachNode(fnc: (node: Node) => void): void;
  getNodes(): Array<Node>;
  addNode(_id: string, node: Node): void;
  getNode(nodeId: string): Node;
  maxNodeWidth(): number;
  maxNodeHeight(): number;
  // #endregion

  // #region Link functions
  addLink(link: Link): void;
  getLinks(): Array<Link>;
  getLink(linkId: string): LINK_TYPE;
  forEachLink(fnc: (link: Link) => void): void;
  getSourceLinksWithNodes(node: Node): {
    sourceLinks: LINK_TYPE[];
    sourceNodes: NODE_TYPE[];
  };
  getTargetLinksWithNodes(node: Node): {
    targetLinks: LINK_TYPE[];
    targetNodes: NODE_TYPE[];
  };
  // #endregion

  // #region Column functions

  maxColumns(): number;

  forEachNodeAtColumn(
    columnIndex: number,
    fnc: (nodes: Node[]) => void,
    sortOn?: keyof Node,
  );
  forEachColumn(
    fnc: (nodes: Node[], columnIndex: number) => void,
    sortOn?: keyof Node,
  ): void;
  forEachColumnReverse(fnc: (nodes: Node[], columnIndex: number) => void): void;

  maxNodesInColumns(): number;
  linksInColumn(): Map<number, Link[]>;
  maxLinksInColumns(): number;
  // #endregion

  removeLinksFromIndex(type: string): void;

  removeVirtualNodesFromIndex(): void;

  getMinY(): number;
  filterLinks(predicate: (link: Link) => boolean): Link[];
  sameColumnLinks(
    getNode: (link: Link) => Node,
    column: number,
    circularLinkType: CircularLinkType | undefined,
  ): Link[];
};

export type GraphSettings<NODE_TYPE extends Node = Node> = {
  width: number;
  padding: number;
  height: number;
  paddingRatio: null;
  nodePadding: number;
  verticalMargin: number;
  baseRadius: number;
  nodeDimensions: (node: any) => { width: number; height: number };
  scale: number;
  iterations: number;
  minNodePadding: { x: number; y: number };
  // minLinkLength: number;
  virtualNodePadding: number;
  circularLinkGap: number;
  nodeAllowedInFirstColumn: (node: any) => boolean;
  nodeAllowedInLastColumn: (node: any) => boolean;
};
