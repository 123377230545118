import { formatNumberToPrintFormat } from '@sympheny/utils/format';
import { nullOrUndefined } from '@sympheny/utils/rxjs';

import {
  EhubNode,
  exportNodeTypes,
  inputNodeTypes,
  NodeType,
} from './ehub.model';
import { ArrowPosition, Link } from '../utils/model';

const sortIndex: Partial<Record<NodeType, string>> = {
  EC: '99',
};

export const isEhubVirtualNode = (d: EhubNode) => {
  return d.type === 'EC' || d.aggregate;
};
export const EhubSortBy = (n: EhubNode): string => {
  const index = sortIndex[n.type] ?? '00';
  return `${index}_${n.type}_${n.name}`;
};
export const EhubNodeTooltip = (d: EhubNode): string | null => {
  if (d.aggregate) return null;
  if (isEhubVirtualNode(d)) return d.edgeLabel ?? d.label ?? d.name;
  if (!d.unit) {
    return EhubNodeText(d);
  }
  return `
          ${EhubNodeText(d)}<br />
          ${nullOrUndefined(d.capacity) ? '-' : formatNumberToPrintFormat(d.capacity)} ${d.unit?.unit}
          `;
};

export const EhubNodeText = (d: EhubNode): string | null => {
  if (isEhubVirtualNode(d)) return null;
  return d.edgeLabel ?? d.label ?? d.name;
};

export const EhubArrow = (drawDoubleArrow: boolean) => {
  if (!drawDoubleArrow) return null;

  const doubleArrow: NodeType[] = ['LINK_IN', 'LINK_OUT'];
  return (
    d: Link,
    source: EhubNode,
    target: EhubNode,
  ): ArrowPosition | null => {
    return doubleArrow.includes(source.type) ||
      doubleArrow.includes(target.type)
      ? 'double'
      : null;
  };
};

export const EhubLinkTooltip = (
  d: Link,
  source: EhubNode,
  target: EhubNode,
) => {
  const sourceT = source.edgeLabel ?? source.label ?? source.name;
  const targetT = target.edgeLabel ?? target.label ?? target.name;
  const arrow =
    source.sameSourceTarget || target.sameSourceTarget ? '<->' : '->';
  return `${sourceT} ${arrow} ${targetT}`;
};

const grayColor = '#c0c0c0';
const ignoreGrayOut: NodeType[] = ['EC', 'DEMAND'];

const hasNoCapacity = (d: EhubNode): boolean => {
  return d && ignoreGrayOut.indexOf(d.type) < 0 && !d.capacity;
};

export const EhubNodeColor =
  (grayNoCapcity: boolean) =>
  (d: EhubNode): string => {
    if (d.aggregate) return 'orange';
    if (grayNoCapcity && hasNoCapacity(d)) return grayColor;
    return d.color;
  };

export const EhubLinkColor =
  (grayNoCapcity: boolean) =>
  (
    d: any,
    { source, target }: { source: EhubNode; target: EhubNode },
  ): string => {
    if (grayNoCapcity && hasNoCapacity(target)) return grayColor;
    if (grayNoCapcity && hasNoCapacity(source)) return grayColor;
    return d.color;
  };

export const defaultWidth = 150;
export const defaultHeight = 50;
export const EhubMinHorizontalNodeX = defaultWidth / 2;
export const EhubMinVerticalNodeY = 20;
const defaultSquare = defaultHeight / 2;

export const EhubNodeDimension = (
  d: EhubNode,
): { width: number; height: number } => {
  if (!d) return { width: defaultWidth, height: defaultHeight };
  if (d.aggregate) return { width: 0, height: defaultHeight };
  if (!isEhubVirtualNode(d))
    return { width: defaultWidth, height: defaultHeight };

  return { width: defaultSquare, height: defaultSquare };
};

export const EhubFirstColumnAllowed = (node: EhubNode): boolean => {
  return inputNodeTypes.some((type) => type === node.type);
};
export const EhubLastColumnAllowed = (node: EhubNode): boolean => {
  return exportNodeTypes.some((type) => type === node.type);
};
